import React from "react"
import { Link } from "gatsby"
import "./ContentImage.scss"
import ButtonOutline from "../ButtonOutline"
const ContentImage = ({
  src,
  children
}) => {
  return (
    <div
      className="content-image"
      style={{
        backgroundImage: `linear-gradient(180deg,rgba(154,0,14,0) 0%,#000000 100%),url(${src})`
      }}
    >
      <div
        className="content-image-inner"
      >
        {children}
      </div>
    </div>
  )
}
export default ContentImage