import React, { useEffect, useState } from "react"
import { graphql, Link, useScrollRestoration, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ButtonOutline from "../../components/ButtonOutline"
import ProductCart from "../../components/ProductCart"
import ContentImage from "../../components/ContentImage"
import SubBanner from "../../components/SubBanner"
import Heading from "../../components/Heading"
import Banner from "../../components/Banner"

// sample image
import imageSample from "../../assets/images/backgound-content.jpg"
import noImage from "../../assets/images/no-img.jpg"
import subbanner from "../../assets/images/subbanner.jpg"
import backgroundImg from "../../components/images/shop-banner.jpg"
import "./shop.scss"
import Loading from "../../components/Loading"
import { useGetAllProducts } from "../../hooks/useGetAllProducts"
const Shop = props => {
  const { listProducts, loading } = useGetAllProducts()
  const uspData = props?.data?.wp?.siteOptions?.acfShopPage?.uspSection?.items
  const available_product = listProducts?.edges.filter(
    el => el.node.regularPrice !== null && el.node.hasOwnProperty("regularPrice")
  )
  const bannerImage = [
    {
      image: {
        sourceUrl: backgroundImg
      },
      heading: "Australian Made Fermented SmallGoods"
    }
  ]
  const seo = {
    title: "Backa Australia - Shop Online for Quality Smallgoods",
    description:
      "Buy Salami online - Backa Smallgoods: All Natural, Fermented and Shelf Stable. The Salamis you know and love are now available for online ordering."
  }
  return (
    <Layout pageClass="page-shop">
      <Seo title={seo.title} description={seo.description} />
      <Banner banner={bannerImage} btnNext={true}>
        <Link className="custom-link" to="/shop/">
          <span>Free Shipping on Orders of $75 or more*</span>
          <span className="show-on-hover">Let's get started</span>
        </Link>
      </Banner>
      <div className="usp-section">
        <div className="usp-section-list container">
          {uspData?.map((item) => {
            return <div key={item.title} className="usp-section-item">{item.title}</div>
          })}
        </div>
      </div>
      <div className="main-section" id="main-section">
        <Heading color="#0a7611" supText="100% ARTISAN CRAFTED" title="SELECTION">
          <p>
            Buy Salamis Online – Our range of artisan crafted&nbsp; small-goods is now available for
            home delivery!&nbsp; Spend $75 or more* and receive Free Shipping.&nbsp; Please browse
            our selection below.
          </p>
          <h6>
            *Retail customers only.&nbsp; Wholesale customers should refer to their supplier
            agreement with Backa Australia.
          </h6>
        </Heading>
      </div>
      <div className="banner-shop">
        <SubBanner src={subbanner} to="#" />
      </div>
      {!loading ? (
        <div className="product-grid">
          {available_product.map(product => {
            return (
              <ProductCart
                key={product.node.id}
                productId={product.node.id}
                productDatabaseId={product.node.databaseId}
                regularPrice={product.node.regularPrice}
                salePrice={product.node.salePrice}
                productName={product.node.name}
                productImage={
                  product.node.featuredImage ? product.node.featuredImage.node.sourceUrl : noImage
                }
                slug={product.node.slug}
                stockStatus={product.node.stockStatus}
                isProductConfig={product.node.__typename === "VariableProduct"}
                isSales={product.node.onSale}
                backInStock={product.node.acfProductData.backInStockNotificationForm}
              ></ProductCart>
            )
          })}
        </div>
      ) : (
        <div className="products-loading">
          <Loading />
        </div>
      )}
      <div className="container">
        <ContentImage src={imageSample}>
          <h2>Share a Recipe</h2>
          <p>
            Do you have a favourite use for Backa products ? Share your recipes with us and if we
            publish yours on the website , you’ll receive a free gift as our thanks.
          </p>
          <Link to="#">
            <ButtonOutline typeStyle="white">Tell Us</ButtonOutline>
          </Link>
        </ContentImage>
      </div>
    </Layout>
  )
}

export default Shop
export const shopPageQuery = graphql`
  query {
    wp {
      siteOptions {
        acfShopPage {
          uspSection {
            items {
              fieldGroupName
              title
            }
          }
        }
      }
    }
  }
`
